import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useState } from "react";
import { StripeMpanHome } from "./home";
import { STRIPE_API_KEY_TEST, STRIPE_API_KEY_LIVE } from "../../../services/env-process";

export default function MpanRoot({ isTestMode }: { isTestMode: boolean }) {
  const [stripeSecretApiKey] = useState<string>((isTestMode ? STRIPE_API_KEY_TEST : STRIPE_API_KEY_LIVE)!);

  const stripePromise = loadStripe(stripeSecretApiKey);

  const [paymentOptions] = useState<StripeElementsOptions>({
    mode: "setup",
    currency:"gbp",
    appearance: {
      theme: "flat",
    },
  });

  return (
    <Elements stripe={stripePromise} options={paymentOptions}>
      <StripeMpanHome isTestMode={false} setIsTestSwitchDisabled={() => {}} />
    </Elements>
  );
}
