import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useState } from "react";
import CheckoutForm from "./Form";
import { ApplePayOption } from "@stripe/stripe-js/dist/stripe-js/elements/apple-pay";

export default function CheckoutRoot(props: {
  apiKey: string;
  amount: number;
  currency: string;
  isTestMode: boolean;
  options?: ApplePayOption;
  mode: "payment" | "setup" | "subscription";
  onCancel: () => void;
}) {
  const stripePromise = loadStripe(props.apiKey);

  const [paymentOptions] = useState<StripeElementsOptions>({
    mode: props.mode,
    amount: props.amount,
    currency: props.currency,
    appearance: {
      theme: "flat",
    },
  });

  return (
    <Elements stripe={stripePromise} options={paymentOptions}>
      <CheckoutForm
        apiKey={props.apiKey}
        amount={props.amount}
        currency={props.currency}
        isTestMode={props.isTestMode}
        onCancel={props.onCancel}
        options={props.options}
      />
    </Elements>
  );
}
