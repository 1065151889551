import { Container, Stack, Button } from "@mantine/core";
import * as apiService from "../../../services/api-service";
import { useState } from "react";
import { STRIPE_API_KEY_LIVE, STRIPE_API_KEY_TEST } from "../../../services/env-process";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

export function StripeMpanHome({
  isTestMode,
  setIsTestSwitchDisabled,
}: {
  isTestMode: boolean;
  setIsTestSwitchDisabled: (enabled: boolean) => void;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeSecretApiKey] = useState<string>((isTestMode ? STRIPE_API_KEY_TEST : STRIPE_API_KEY_LIVE)!);

  const [message, setMessage] = useState<string | null>(null);

  const requestSetup = () => {
    setMessage(null);
    if (!stripe || !elements) {
      setMessage("Stripe currently unavailable");
      return;
    }
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setMessage("Card Element unavailable");
      return;
    }

    apiService.stripeMpanSetup(stripeSecretApiKey).then(async (data: any) => {
      const secret = data.client_secret;
      if (secret) {
        const result = await stripe.confirmCardSetup(secret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (result.error) {
          setMessage(result.error.message ?? "");
        } else {
          setMessage("Payment method saved successfully!");
        }
      }
      console.log("intent", data);
    });
  };

  const stripePromise = loadStripe(stripeSecretApiKey);

  const [paymentOptions] = useState<StripeElementsOptions>({
    mode: "setup",
    currency: "gbp",
    appearance: {
      theme: "flat",
    },
  });

  return (
    <Elements stripe={stripePromise} options={paymentOptions}>
      <Container size="xs">
        {" "}
        <Stack>
          <CardElement />

          <Button color={isTestMode ? "orange" : "blue"} onClick={requestSetup}>
            Setup Card
          </Button>
          {message && <p>{message}</p>}
        </Stack>
      </Container>
    </Elements>
  );
}
