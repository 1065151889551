import React from "react";
import ReactDOM from "react-dom/client";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { MantineProvider } from "@mantine/core";
import AppShellRoot from "./components/AppShellRoot";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <MantineProvider defaultColorScheme="auto">
      <AppShellRoot />
    </MantineProvider>
  </React.StrictMode>
);
