import {
  Button,
  Container,
  NumberInput,
  Stack,
  Transition,
  NativeSelect,
  Flex,
  TextInput,
  SegmentedControl,
  Input,
  ScrollArea,
  Text,
} from "@mantine/core";
import CheckoutRoot from "./Root";
import { useEffect, useState } from "react";
import { IS_IN_DEVELOPMENT, STRIPE_API_KEY_LIVE, STRIPE_API_KEY_TEST } from "../../services/env-process";
import { CheckoutTypeRecurring } from "./types/Recurring";
import { ApplePayOption } from "@stripe/stripe-js/dist/stripe-js/elements/apple-pay";
import { CheckoutTypeAutoReload } from "./types/AutoReload";
import { CheckoutTypeDeferred } from "./types/Deferred";
import { isSafari } from "react-device-detect";
import moment from "moment";

export default function CheckoutAmount(props: {
  isTestMode: boolean;
  setIsTestSwitchDisabled: (enabled: boolean) => void;
}) {
  const stripeSecretApi = (props.isTestMode ? STRIPE_API_KEY_TEST : STRIPE_API_KEY_LIVE)!;
  const [currency, setCurrency] = useState<string>(localStorage.getItem("currency") ?? "GBP");
  const [amount, setAmount] = useState<number>(40);
  const transitionDuration = 200;
  // The current options for Apple Pay
  const [paymentOptions, setPaymentOptions] = useState<ApplePayOption>();
  // All available payment types
  const paymentTypes = [
    { title: "Standard", subtitle: "Payment", value: "payment" },
    { title: "Recurring", subtitle: "MPAN", value: "recurring", disabled: false },
    { title: "Auto Reload", subtitle: "MPAN", value: "reload", disabled: true },
    { title: "Deferred", subtitle: "MPAN", value: "deferred", disabled: true },
  ];
  // The default payment type value
  const paymentTypeDefault = paymentTypes[0].value;
  // The current payment type selected
  const [paymentType, setPaymentType] = useState(paymentTypeDefault);
  // Determines whether checkout is shown to the user
  const [showCheckout, setShowCheckout] = useState(false);
  // All available currencies
  const currencyList = [
    ...currenciesSupportedByApple.map((c) => {
      return { value: c, label: c };
    }),
    { value: "other", label: "Other" },
  ];

  const [isCustomCurrency, setIsCustomCurrency] = useState<boolean>(false);

  useEffect(() => {
    const savedCurrency = localStorage.getItem("currency") ?? "GBP";
    const savedAmount = localStorage.getItem("amount") ?? 40;

    setAmount(Number(savedAmount));

    // Check if the saved currency is in the list of supported currencies
    if (currencyList.find((c) => c.label === savedCurrency) === undefined) {
      setCurrency(savedCurrency);
      setIsCustomCurrency(true);
    }

    return () => {
      props.setIsTestSwitchDisabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currency === "other") {
      setCurrency(localStorage.getItem("currency") ?? "GBP");
      setIsCustomCurrency(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  const checkPaymentType = () => {
    if (paymentType === "payment") {
      // clear any payment options that shouldn't be there
      setPaymentOptions(undefined);
    } else if (
      paymentType === "recurring" &&
      moment(paymentOptions?.recurringPaymentRequest?.regularBilling?.recurringPaymentStartDate).isBefore(moment())
    ) {
      // check date is in the future
      alert("The Start Date of the recurring payment must be in the future");
      return;
    }

    // console.log(paymentOptions);
    props.setIsTestSwitchDisabled(true);
    localStorage.setItem("currency", currency);
    localStorage.setItem("amount", `${amount}`);
    setShowCheckout(true);
  };

  const continueButton = () => {
    return (
      <Flex gap="md">
        <Button
          disabled={!allCurrencyCodes.includes(currency)}
          fullWidth
          size="md"
          color={props.isTestMode ? "orange" : "blue"}
          onClick={checkPaymentType}
        >
          Pay
        </Button>
      </Flex>
    );
  };

  return (
    <Container size="xs">
      <Stack>
        {/* Top Content */}
        <Flex gap="md" align="flex-end">
          <NumberInput
            size="md"
            label="Amount"
            description="Must be more than £0.35 in local currency"
            disabled={showCheckout}
            defaultValue={0.4}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator=","
            min={0.4}
            step={0.01}
            value={amount / 100}
            onChange={(e) => {
              const newValue = Number(e) * 100;
              setAmount(Math.trunc(newValue));
            }}
          />
          {isCustomCurrency ? (
            <TextInput
              label="Currency"
              maxLength={3}
              size="md"
              disabled={showCheckout}
              value={currency}
              error={!allCurrencyCodes.includes(currency) && "Invalid currency code"}
              onChange={(e) => setCurrency(e.currentTarget.value.toUpperCase())}
            />
          ) : (
            <NativeSelect
              size="md"
              label="Currency"
              disabled={showCheckout}
              data={currencyList}
              value={currency}
              onChange={(e) => setCurrency(e.currentTarget.value)}
            />
          )}
        </Flex>

        {/* Load Checkout */}
        <Transition
          mounted={showCheckout}
          transition="scale-y"
          enterDelay={transitionDuration}
          duration={transitionDuration}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={styles}>
              <CheckoutRoot
                apiKey={stripeSecretApi}
                amount={amount}
                currency={currency.toLocaleLowerCase()}
                isTestMode={props.isTestMode}
                options={paymentOptions}
                mode={paymentType !== "payment" ? "setup" : "payment"}
                onCancel={() => {
                  props.setIsTestSwitchDisabled(false);
                  setShowCheckout(false);
                }}
              />
            </div>
          )}
        </Transition>

        {/* Payment Types */}
        <Transition
          mounted={!showCheckout}
          transition="scale-y"
          enterDelay={transitionDuration}
          duration={transitionDuration}
          timingFunction="ease"
          keepMounted
        >
          {(styles) => (
            <Stack style={styles}>
              {(isSafari || IS_IN_DEVELOPMENT) && (
                <ScrollArea>
                  <Input.Wrapper size="md" label="Payment Type">
                    <SegmentedControl
                      fullWidth
                      size="md"
                      radius="md"
                      value={paymentType}
                      onChange={(v) => {
                        setPaymentType(v);
                        if (v === paymentTypeDefault) {
                          setPaymentOptions(undefined);
                        }
                      }}
                      data={paymentTypes.map((t) => {
                        return {
                          value: t.value,
                          label: (
                            <Stack gap={0} align="start">
                              <Text>{t.title}</Text>
                              <Text fz="xs" c="dimmed">
                                {t.subtitle}
                              </Text>
                            </Stack>
                          ),
                          disabled: t.disabled ?? false,
                        };
                      })}
                    />
                  </Input.Wrapper>
                </ScrollArea>
              )}

              <Transition
                mounted={paymentType === "payment"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => <div style={styles}>{continueButton()}</div>}
              </Transition>

              <Transition
                mounted={paymentType === "recurring"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={styles}>
                    <CheckoutTypeRecurring
                      amount={amount}
                      onChange={(pr) => {
                        if (paymentType === "recurring") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>

              <Transition
                mounted={paymentType === "reload"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={{ ...styles }}>
                    <CheckoutTypeAutoReload
                      amount={amount}
                      onChange={(pr) => {
                        if (paymentType === "reload") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>

              <Transition
                mounted={paymentType === "deferred"}
                transition="scale-y"
                enterDelay={transitionDuration}
                duration={transitionDuration}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <Stack style={{ ...styles }}>
                    <CheckoutTypeDeferred
                      onChange={(pr) => {
                        if (paymentType === "deferred") {
                          setPaymentOptions(pr);
                        }
                      }}
                    />
                    {continueButton()}
                  </Stack>
                )}
              </Transition>
            </Stack>
          )}
        </Transition>
      </Stack>
    </Container>
  );
}

export const applePaySupportedCurrencies = ["AUD", "CAD", "CZK", "EUR", "GBP", "SEK", "USD"];
export const allCurrencyCodes = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "FOK",
  "GBP",
  "GEL",
  "GGP",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "IMP",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JEP",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KID",
  "KMF",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STN",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TVD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UYU",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XDR",
  "XOF",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL",
];
// The currency codes that are not supported by Apple Pay
export const currenciesSupportedByApple = allCurrencyCodes.filter((code) => applePaySupportedCurrencies.includes(code));
